// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-africa-js": () => import("./../src/pages/blog/africa.js" /* webpackChunkName: "component---src-pages-blog-africa-js" */),
  "component---src-pages-blog-americas-js": () => import("./../src/pages/blog/americas.js" /* webpackChunkName: "component---src-pages-blog-americas-js" */),
  "component---src-pages-blog-asia-js": () => import("./../src/pages/blog/asia.js" /* webpackChunkName: "component---src-pages-blog-asia-js" */),
  "component---src-pages-blog-australia-js": () => import("./../src/pages/blog/australia.js" /* webpackChunkName: "component---src-pages-blog-australia-js" */),
  "component---src-pages-blog-europe-js": () => import("./../src/pages/blog/europe.js" /* webpackChunkName: "component---src-pages-blog-europe-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

